@import "~bootstrap/scss/bootstrap";

h1 {
  // testing
  color: olive;
}

.fit-view {
  max-height: 100dvh;
  height: auto;
  width: 100%;
  display: flex;
  * {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: auto;
    height: 100%;
  }
}

@include media-breakpoint-up(lg) {
  .fit-view {
    height: 100dvh;
    width: auto;
  }
}

body {
  background: #101010;
}
